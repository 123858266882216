import React from "react"
import { styled } from "../../stitches.config";

import { useScript } from "../../helpers/useScript"

const Firework = ({ src, children }) => {
    useScript(src)

    return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled("div",{
    paddingTop: "18px"
});

export default Firework