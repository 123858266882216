import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo from "./assets/logo.svg";
import { ReactComponent as LogoComponent } from "./assets/logo.svg?react";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#423ea8",
    primary: "#2E2B74",
    primary60: "#dbdaf1",
    secondary: "#FD7F67",
    secondary120: "#FFBA5A",
};

const svgColorFilter: string = "brightness(0) saturate(100%) invert(11%) sepia(66%) saturate(2972%) hue-rotate(236deg) brightness(94%) contrast(89%);"

//SocialMedia links
const socialMediaLinks = {
    youtube: 'https://www.youtube.com/@TopNewsyPL'
}

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        TopNewsy
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        Ups, nie mamy nic na ten temat, ale nie szkodzi. Przeczytaj wiele innych interesujących tematów.
    </>
);

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    socialMediaLinks,
    brandTitle,
    errorPageText, errorPageImageDesktop, errorPageImageMobile
};

export default BrandConifg;