import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo from "./assets/logo.svg";
import { ReactComponent as LogoComponent } from "./assets/logo.svg?react";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#E45A00",
    primary: "#FF6400",
    primary60: "#FFEADC",
};

const svgColorFilter: string = "invert(38%) sepia(70%) saturate(3051%) hue-rotate(6deg) brightness(109%) contrast(102%)"

//SocialMedia links
const socialMediaLinks = {
    facebook: 'https://www.facebook.com/natematpl',
    instagram: 'https://www.instagram.com/natematpl/',
    tiktok: 'https://www.tiktok.com/@natemat.pl',
    youtube: 'https://www.youtube.com/@natematpl'
};

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        na<span>:</span>Temat
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        Ups, nie mamy nic na ten temat, ale nie szkodzi. Przeczytaj wiele innych interesujących tematów.
    </>
);

//FooterDescription
const footerDescription = "Informacje i opinie, którymi żyją Polacy.";

//PushPushGo worker
const pushPushWorkerUrl = "https://s-eu-1.pushpushgo.com/66951fb535487da0920f67f1/worker.js";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    socialMediaLinks,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription,
    pushPushWorkerUrl
};

export default BrandConifg;