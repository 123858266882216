import React from "react"

import Firework from "./Firework"

import { useScript } from "../../helpers/useScript"
import { withConsent } from "../HOCs/withConsent"

const FireworkWrapper = ({ slot }) => {
    useScript(slot.scriptTag);

    return <Firework src='//asset.fwpub1.com/js/embed-feed.js'>
        <fw-embed-feed
            channel={slot.channel}
            playlist={slot.playlist}
            mode="row"
            open_in="default"
            max_videos="0"
            placement="middle"
            player_placement="bottom-right"
            branding="false"
            size="medium"
        ></fw-embed-feed>
        {slot.pixel && <img src={slot.pixel} border="0" alt="" width="0" height="0" />}
        {slot.pixel2 && <img src={slot.pixel2} border="0" alt="" width="0" height="0" />}
        {slot.pixel3 && <img src={slot.pixel3} border="0" alt="" width="0" height="0" />}
    </Firework>
}

export default withConsent(FireworkWrapper)