import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo from "./assets/logo.svg";
import { ReactComponent as LogoComponent } from "./assets/logo.svg?react";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#2C63CD",
    primary: "#3376F6",
    primary60: "#D6E4FD",
};

const svgColorFilter: string = "invert(40%) sepia(85%) saturate(2987%) hue-rotate(208deg) brightness(98%) contrast(98%);"

//SocialMedia links
const socialMediaLinks = {
    facebook: 'https://www.facebook.com/dadheropl',
    instagram: 'https://www.instagram.com/dadheropl/',
    tiktok: 'https://www.tiktok.com/@dadhero.pl',
    youtube: 'https://www.youtube.com/@mamadupl'
};

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        dad<span>:</span>HERO
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        dadherozero.
        <br />
        Nie mamy nic na ten temat, ale nie szkodzi. Przeczytaj w dadhero inne interesujące teksty.
    </>
);

//FooterDescription
const footerDescription = "Fajnie być tatą";

//PushPushGo worker
const pushPushWorkerUrl = "https://s-eu-1.pushpushgo.com/66952b6335487da09214c9ce/worker.js";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    socialMediaLinks,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription,
    pushPushWorkerUrl
};

export default BrandConifg;